import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { StyledH2 } from '../../styles/globalStyles';
import { StyledPara } from '../AccountAgreement/AgreementTermsSection';

const SectionWrap = styled.section`
  margin: 0;
  padding: 80px 0;

  ${StyledH2} {
    font-size: 24px;
    font-family: Proximanova-Medium;
    line-height: 30px;
  }

  ${StyledPara} {
    font-size: 16px;
    line-height: 22px;
  }
`;

const Section4 = () => {
  return (
    <SectionWrap>
      <Container>
        <Row className='justify-content-center'>
          <Col md={11}>
            <Row className='align-items-center'>
              <Col md={5} className='text-center mb-4 mb-md-0'>
                <img src='/cause-funds/Charitable Impact-Cause-Fund_circular_light.png' />
              </Col>

              <Col md={7}>
                <StyledH2>A fresh approach for measurable change</StyledH2>
                <StyledPara>
                  What needs to be done to bring about genuine progress and
                  tangible change? And who’s poised to make it happen? Cause
                  Funds kick off with a network of experts, changemakers, and
                  people with lived experience who tackle these questions and
                  create a roadmap for maximum impact.
                </StyledPara>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  );
};

export default Section4;
