import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { StyledH2 } from '../../styles/globalStyles';

const SectionWrap = styled.section`
  margin: 0;
  padding: 80px 0;
  background-color: #f5f8fc;
`;

const Styled_H2 = styled(StyledH2)`
  font-family: ProximaNova-Medium;
  font-size: 24px;
  line-height: 30px;
  margin: 0;
`;

const StyledPara = styled.p`
  color: #282828;
  font-family: ProximaNova-Regular;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 30px;
`;

const BasicsList = styled.div`
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 3px;
    background: linear-gradient(to bottom, #055ce5 50%, #7ad8da 50%);

    @media only screen and (max-width: 768px) {
      display: none;
    }
  }
`;

const BasicsListItem = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  line-height: 1.14285714em;
  list-style-position: outside;
  list-style-type: none;
  margin-bottom: 25px;
  table-layout: fixed;
`;

const Content = styled.div`
  color: #67686c;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 18px;
  line-height: 24px;
  margin-left: 1rem;
  margin-top: 10px;
`;

const Image = styled.img`
  display: inline-block;
  height: 4em;
  margin-top: 5px;
  width: 4em;
`;

const ContentText = styled.div`
  color: #1f1f1f;
  float: left;
  font-size: 16px;
  font-family: ProximaNova-Regular;

  strong {
    font-family: ProximaNova-Medium;
    color: #1f1f1f;
  }
`;

const ListPointsData = [
  {
    img: '/cause-funds/icon-1.png',
    title: 'Champion your cause',
    text: 'Gain a powerful platform backed by expert advice and support from our team to elevate your cause and amplify your voice.',
  },
  {
    img: '/cause-funds/icon-2.png',
    title: 'Collaborate with experts',
    text: 'Connect, collaborate, and strive for common goals within a community of like-minded individuals.',
  },
  {
    img: '/cause-funds/icon-3.png',
    title: 'Inspire donor confidence',
    text: 'Build trust and awareness through transparency and measurable impact, getting the best return on your investment. ',
  },
];

const Section3 = () => {
  return (
    <SectionWrap>
      <Container>
        <Row className='justify-content-center'>
          <Col md={5}>
            <div className='pe-md-2'>
              <Styled_H2 className='mb-4'>
                Cause Funds need changemakers like you to revolutionize the
                world of giving
              </Styled_H2>

              <StyledPara>
                Whether you lead, advise, manage, donate, or simply care deeply,
                this is your opportunity to be the change you want to see in the
                world.
              </StyledPara>
            </div>
          </Col>

          <Col md={7}>
            <BasicsList className='ps-md-5'>
              {ListPointsData.map(({ img, title, text }) => (
                <BasicsListItem key={title}>
                  <Image src={img} />
                  <Content>
                    <ContentText>
                      <strong>{title}:</strong> {text}
                    </ContentText>
                  </Content>
                </BasicsListItem>
              ))}
            </BasicsList>
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  );
};

export default Section3;
