import React from 'react';
import { Col, Container, ListGroup, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { StyledH2 } from '../../styles/globalStyles';
import { StyledPara } from '../AccountAgreement/AgreementTermsSection';
import { StyledListGroupItem } from '../Contact/GetIntouchSection';
import ListIcons from '../ListIcons/ListIcons';

const SectionWrap = styled.section`
  margin: 0;
  padding: 80px 0;

  strong {
    font-family: ProximaNova-Medium;
    color: #1f1f1f;
  }

  ${StyledH2} {
    font-size: 24px;
    font-family: Proximanova-Medium;
    line-height: 30px;
  }

  ${StyledPara} {
    font-size: 16px;
    line-height: 22px;
  }

  a {
    color: #055ce5 !important;

    span {
      color: #424242;
    }
  }
`;

const Section4 = () => {
  return (
    <SectionWrap>
      <Container>
        <Row className='justify-content-center'>
          <Col md={11}>
            <Row className='align-items-center'>
              <Col md={6} className='text-center mb-4 mb-md-0'>
                <img src='/cause-funds/Haley_Kevin_Head_shot.png' />
              </Col>

              <Col md={6}>
                <StyledH2>
                  To learn more and get involved, please contact:
                </StyledH2>
                <StyledPara>
                  <strong>Kevin Moorhead</strong> &{' '}
                  <strong>Haley Turner-Collinge</strong> <br />
                  Cause Funds Team
                </StyledPara>

                <ListGroup as='ul'>
                  <StyledListGroupItem as='li'>
                    <a
                      href='mailto:causefunds@charitableimpact.com'
                      target='_blank'>
                      <ListIcons className='mail' />
                      causefunds@charitableimpact.com
                    </a>
                  </StyledListGroupItem>
                  <StyledListGroupItem as='li'>
                    <a>
                      <ListIcons className='phone' />
                      1-877-531-0580 <span>(toll-free)</span>
                    </a>
                  </StyledListGroupItem>
                  <StyledListGroupItem as='li'>
                    <a>
                      <ListIcons className='mapmarker-small' />
                      #1250 - 1500 W Georgia St Vancouver, BC V6G 2Z6 Canada
                    </a>
                  </StyledListGroupItem>
                </ListGroup>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  );
};

export default Section4;
