import React from 'react';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import { BannerHeading } from '../Charities/BannerSection';

const SectionWrap = styled.section`
  height: 400px;
  position: relative;
  z-index: 10;

  ${BannerHeading} {
    font-family: Proximanova-Light;
    font-weight: 400;
  }
`;

const StripShape = styled.div`
  width: 10px;
  height: 100%;
  background: #81459c;
  display: none;
`;

const Image = styled.img`
  position: absolute;
`;

const Image1 = styled(Image)`
  top: 0;
  left: 0;
`;
const Image2 = styled(Image)`
  top: 10px;
  right: 115px;
`;

const Image3 = styled(Image)`
  top: 0;
  right: 0;
`;

const Image4 = styled(Image)`
  top: 0;
  left: 420px;
`;

const Image5 = styled(Image)`
  top: 130px;
  left: 120px;
`;

const Image6 = styled(Image)`
  top: 0;
  right: 180px;
  z-index: -1;
`;

const ImageGroups = styled.div`
  position: absolute;
  inset: 0;
  z-index: -10;

  @media only screen and (max-width: 768px) {
    position: static;
    z-index: 10;
    display: flex;
    align-items: start;
    order: 2;
    margin-top: 20px;
    gap: 10px;

    ${Image} {
      position: static;
      width: 140px;
    }

    ${StripShape} {
      display: block;
    }

    ${Image6} {
      position: absolute;
      width: 40%;
      right: 0;
    }

    ${Image3},${Image4}, ${Image5} {
      display: none;
    }
  }
`;

const Banner = () => {
  return (
    <SectionWrap className='flex-column justify-content-center d-flex align-items-center'>
      <ImageGroups>
        <StripShape />
        <Image1
          src='/cause-funds/banner-image-1.png'
          width={201}
          className='animate__animated animate__fadeInDown animate__slow animate__delay-1s'
        />
        <Image2
          src='/cause-funds/banner-image-2.png'
          width={201}
          className='animate__animated animate__fadeInUp animate__slow animate__delay-1s'
        />
        <Image3
          src='/cause-funds/banner-image-3.png'
          width={109}
          className='animate__animated animate__fadeInRight animate__fast animate__delay-1s'
        />
        <Image4 src='/cause-funds/banner-shape-1.png' width={78} />
        <Image5 src='/cause-funds/banner-shape-2.png' width={200} />
        <Image6 src='/cause-funds/banner-shape-3.png' width={410} />
      </ImageGroups>
      <Container>
        <div>
          <BannerHeading className='text-center'>
            Charitable Impact <br /> Cause Funds
          </BannerHeading>
        </div>
      </Container>
    </SectionWrap>
  );
};

export default Banner;
