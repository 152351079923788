import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { StyledH2 } from '../../styles/globalStyles';
import { StyledPara } from '../AccountAgreement/AgreementTermsSection';

const SectionWrap = styled.section`
  margin: 0;
  padding: 40px 0 80px;

  ${StyledH2} {
    font-size: 24px;
    font-family: Proximanova-Medium;
    line-height: 30px;
  }

  ${StyledPara} {
    font-size: 16px;
    line-height: 22px;
  }
`;

const Section2 = () => {
  return (
    <SectionWrap>
      <Container>
        <Row className='justify-content-center'>
          <Col md={10}>
            <Row className='align-items-center'>
              <Col md={9}>
                <StyledH2>Welcome to the future of charitable giving</StyledH2>
                <StyledPara>
                  Cause Funds are blazing a trail to transform how Canadians
                  give, and changemakers like you are leading the charge.
                </StyledPara>

                <StyledPara>
                  Cause Funds address a common roadblock for donors: they know
                  what they care about but are unsure where to channel their
                  support. By pooling resources, wisdom, and expertise, donors
                  can confidently and effectively support the causes they care
                  about and trust every dollar will achieve its maximum
                  potential.
                </StyledPara>

                <StyledPara>
                  Join forces with a powerhouse team of experts, leaders, and
                  changemakers to break down silos and drive tangible,
                  measurable change. 
                </StyledPara>
              </Col>

              <Col md={3} className='d-none d-md-block text-center'>
                <img src='/cause-funds/Charitable Impact Cause-Fund_circular_light-background.png' />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  );
};

export default Section2;
