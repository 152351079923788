import React from 'react';
import { Col, Container, ListGroup, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { StyledH2 } from '../../styles/globalStyles';
import { StyledPara } from '../AccountAgreement/AgreementTermsSection';

const SectionWrap = styled.section`
  margin: 0;
  padding: 80px 0;

  ${StyledH2} {
    font-size: 24px;
    font-family: Proximanova-Medium;
    line-height: 30px;
  }

  ${StyledPara} {
    font-size: 16px;
    line-height: 22px;
  }

  .list-group {
    ${StyledH2} {
      color: #663387;
    }

    ${StyledPara} {
      color: #6e6f72;
    }
  }
`;

const ListData = [
  {
    text: 'Cause Funds begin with an impassioned changemaker devoted to a cause. Recognizing the challenges that stand in the way of progress, they’re determined to clear a path for cause supporters to take action.',
  },
  {
    text: 'The Advisory Council is formed. A network of subject matter experts, advocates, and those with lived experience in a causal area unite and get to work.',
  },
  {
    text: 'The Advisory Council establishes a goal—what needs to be done to move a cause forward—and determines the priority actions needed to create measurable change.',
  },
  {
    text: 'With the greatest needs identified, the Advisory Council selects a network of high-impact charities and qualified donees who are ready to tackle the toughest challenges.',
  },
  {
    text: 'The Cause Fund enables generous Canadians to discover and confidently support the causes they care about. ',
  },
  {
    text: `We're big on accountability around here. Transparency inspires trust and confidence in  donors. Impact data is gathered regularly and reported back to donors so they can see the impact and progress of their support.`,
  },
];

const Section5 = () => {
  return (
    <SectionWrap>
      <Container>
        <Row>
          <Col md={7} className='mb-4'>
            <StyledH2 className='mb-2'>How do Cause Funds work?</StyledH2>
            <StyledPara className='m-0'>
              Cause Funds are powered by the Charitable Impact Donor-Advised
              Fund, at no cost, and supported by expert advice and assistance
              from our team.
            </StyledPara>
          </Col>
        </Row>

        <Row>
          {ListData.map(({ text }, index) => (
            <Col md={6} lg={4} key={index}>
              <ListGroup className='d-flex gap-4 pb-1 mb-4 flex-row'>
                <StyledH2 style={{ marginTop: -1, fontSize: 23 }}>
                  {index + 1}
                </StyledH2>
                <StyledPara className='m-0'>{text}</StyledPara>
              </ListGroup>
            </Col>
          ))}
        </Row>
      </Container>
    </SectionWrap>
  );
};

export default Section5;
