import React from 'react';
import BannerSection from '../components/CauseFunds/BannerSection';
import Layout from '../components/Layout/Layout';
import Section2 from '../components/CauseFunds/Section2';
import Section3 from '../components/CauseFunds/Section3';
import Section4 from '../components/CauseFunds/Section4';
import Section5 from '../components/CauseFunds/Section5';
import Section6 from '../components/CauseFunds/Section6';

const pageInfo = {
  parent_path: '/solutions',
  path: '/cause-funds',
  title: 'Helping donors support your charity',
  description:
    "If you're a registered charity in Canada, donors can give to you through Charitable Impact. See how it works and get gifts faster by claiming your charity page.",
};

const CauseFunds = () => {
  return (
    <Layout pageInfo={pageInfo}>
      <BannerSection />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Section6 />
    </Layout>
  );
};

export default CauseFunds;
